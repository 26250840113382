import { Box } from '@mui/material';
import ListingHeader from 'components/BizlyOS/Listings/ListingHeader';

export default function Listing() {
    return (
        <Box>
            <ListingHeader state="create" />
        </Box>
    );
}
