import { Box } from '@mui/material';
import { getProposalForVenue } from 'api/bizlyOS';
import ProposalInquryHeader from 'components/BizlyOS/Proposals/ProposalInquryHeader';
import { Spinner } from 'components/Spinner';
import sortBy from 'lodash/sortBy';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

export function ProposalInqury(): React.JSX.Element {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };

    const { data: proposalInquryForm, isLoading } = useQuery({
        queryKey: ['proposal_inqury', venueId, proposalId],
        queryFn: () => getProposalForVenue(venueId, proposalId),
        onSettled(data) {
            if (!data) return {};
            data.options.currencies = data.options.currencies && sortBy(data.options.currencies, 'code');
            return data;
        },
    });

    if (isLoading || !proposalInquryForm) return <Spinner />;

    return (
        <Box height="100%">
            <ProposalInquryHeader proposalInquryForm={proposalInquryForm} />
        </Box>
    );
}
