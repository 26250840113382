export enum VenueStatus {
    Added = 'Added',
    Submitted = 'Submitted',
    ProposalReceived = 'Proposal Received',
    ProposalRejected = 'Proposal Rejected',
    ProposalCancelled = 'Proposal Cancelled',
    ExtensionPending = 'Extension Pending',
    ExtensionRejected = 'Extension Rejected',
    ProposalExpired = 'Proposal Expired',
    InquiryRejected = 'Inquiry Rejected',
    InquiryCancelled = 'Inquiry Cancelled',
    ContractPending = 'Contract Pending',
    Booked = 'Booked',
    BookingCancelled = 'Booking Cancelled',
}
