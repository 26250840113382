import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import Box from '@mui/material/Box';
import { GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import { Tab, TabContainer } from 'components/Ui-V2/TabGroup/TabGroup';
import { useMemo } from 'react';
import { i18n } from 'translation';
import { TabConfigItem, TabValue } from './EventTable';

interface EventTableToolbarProps {
    tab: TabValue;
    onTabChange: (value: TabValue) => void;
    showActionItems?: boolean;
    hasActionItems?: boolean;
}

export const EventTableToolbar = ({ tab, onTabChange, showActionItems, hasActionItems }: EventTableToolbarProps) => {
    const tabConfig = useMemo(() => {
        const baseConfig: Partial<Record<TabValue, TabConfigItem>> = {
            upcoming: {
                icon: <AccessTimeIcon fontSize="small" />,
                label: i18n.homepage.eventTableActions.upcoming,
            },
            createdByOwner: {
                icon: <AccountCircleOutlinedIcon fontSize="small" />,
                label: i18n.homepage.eventTableActions.createdByYou,
            },
            all: {
                icon: <ViewHeadlineIcon fontSize="small" />,
                label: i18n.homepage.eventTableActions.all,
            },
        };

        if (showActionItems) {
            baseConfig.actionItems = {
                icon: <EventAvailableOutlinedIcon fontSize="small" />,
                label: i18n.homepage.eventTableActions.actionItems,
            };
        }

        return baseConfig;
    }, [showActionItems]);

    const handleTabChange = (_: React.SyntheticEvent, newValue: TabValue) => {
        onTabChange(newValue);
    };

    return (
        <GridToolbarContainer>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }}>
                <TabContainer value={tab} onChange={handleTabChange}>
                    {Object.entries(tabConfig).map(([value, { icon, label }]) => (
                        <Tab
                            key={value}
                            icon={icon}
                            label={label}
                            value={value}
                            showNotification={value === 'actionItems' && hasActionItems}
                        />
                    ))}
                </TabContainer>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {/* TODO: Hiding these buttons for now since grid cards need more clarification */}
                    {/* <SwitchGroup value={alignment} exclusive onChange={handleViewChange}>
                        <ToggleButton value="grid">
                            <StyledGridIcon alignment />
                            {i18n.homepage.eventTableActions.grid}
                        </ToggleButton>
                        <ToggleButton value="list">
                            <StyledListIcon alignment />
                            {i18n.homepage.eventTableActions.list}
                        </ToggleButton>
                    </SwitchGroup> */}
                    <GridToolbarQuickFilter />
                </Box>
            </Box>
        </GridToolbarContainer>
    );
};
