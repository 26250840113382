import {
    cancelProposalForm,
    getInquiryRejectionReasons,
    getProposalForm,
    OTHER_REASON_ID,
    postProposalFormContact,
    postVenueSpace,
    putProposalForm,
    putVenueSpace,
    rejectProposalExtension,
    rejectProposalForm,
    sendProposalForm,
    TSpacePOST,
} from 'api';
import { GenericError } from 'components/Error/ErrorFallback';
import FormManager from 'components/ProposalForm/FormManager';
import { TProposalForm, TProposalFormUpdate } from 'components/ProposalForm/types';
import { Spinner } from 'components/Spinner';
import sortBy from 'lodash/sortBy';
import { useSnackbar } from 'notistack';
import { useBizlyOSUser } from 'providers/bizly-os-user';
import React, { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column } from 'ui';
import { enableTurnDown } from 'utils';

const FullScreenCentered = styled(Column)`
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
`;

interface ErrorWithCode {
    code: number;
    message: string;
}

export const Proposals = () => {
    const [loading, setLoading] = React.useState(true);
    const [proposalForm, setProposalForm] = React.useState<Partial<TProposalForm>>({});
    const [rejectionReasons, setRejectionReasons] = React.useState<BizlyAPI.InquiryRejectionReason[]>([]);
    const [rejectionValues, setRejectionValues] = React.useState<{ reason: number; note: string }>();

    const handleRejectionValueChange = ({ value }: { value: typeof rejectionValues }) => {
        setRejectionValues(value);
    };

    const [loadError, setLoadError] = React.useState<ErrorWithCode>();

    const location = useLocation();
    const authError = location.state?.error;
    const hasError = authError || loadError;

    const { venueId, proposalId } = useParams<{ venueId: string; proposalId: string }>();

    // We'll need another way to set the venueId from the Inquiries page
    const { setVenueId } = useBizlyOSUser();

    const safeVenueId = useMemo(() => venueId ?? '', [venueId]);
    const safeProposalId = useMemo(() => proposalId ?? '', [proposalId]);

    React.useEffect(() => {
        if (safeVenueId) {
            setVenueId(safeVenueId);
        }
    }, [safeVenueId, setVenueId]);

    React.useEffect(() => {
        const loadReasons = async () => {
            const { inquiryRejectionReasons } = await getInquiryRejectionReasons();
            setRejectionReasons(inquiryRejectionReasons);
        };

        const loadForm = async () => {
            setLoading(true);
            try {
                const data = await getProposalForm(safeVenueId, safeProposalId);
                await loadReasons();

                data.options.currencies = data.options.currencies && sortBy(data.options.currencies, 'code');
                setProposalForm(data);
            } catch (e) {
                setLoadError(e as ErrorWithCode);
            } finally {
                setLoading(false);
            }
        };
        if (!hasError) loadForm();
    }, [safeVenueId, safeProposalId, hasError]);

    const { enqueueSnackbar } = useSnackbar();

    const onSave = async (newData: Partial<TProposalFormUpdate>) => {
        const { contact, ...newProposalData } = newData;

        const hasContact = !!contact;
        const hasProposal =
            !!newProposalData.proposal ||
            (newProposalData.eventSpaces?.length || 0) > 0 ||
            (newProposalData.guestRooms?.length || 0) > 0;

        try {
            let response = proposalForm;

            if (hasContact) response = await postProposalFormContact(safeVenueId, safeProposalId, newData.contact);

            if (hasProposal) {
                const currencyId = newData?.proposal?.currencyCode
                    ? proposalForm.options?.currencies?.find(
                          currencyOption => currencyOption.code === newData.proposal?.currencyCode
                      )?.id
                    : undefined;
                response = await putProposalForm(safeVenueId, safeProposalId, {
                    eventSpaces: newProposalData.eventSpaces || [],
                    guestRooms: newProposalData.guestRooms || [],
                    proposal: { ...proposalForm.proposal, ...newData.proposal, currencyId },
                });
            }

            setProposalForm(response);
            return true;
        } catch {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
            return false;
        }
    };

    const onSubmit = async () => {
        try {
            const response = await sendProposalForm(safeVenueId, safeProposalId);
            setProposalForm(response);

            return true;
        } catch {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
            return false;
        }
    };

    const onRejectWithReasons = async () => {
        if (!rejectionValues?.reason) {
            enqueueSnackbar(i18n.proposalForm.selectRejectReason, { variant: 'error' });
            return false;
        } else if (rejectionValues?.reason === OTHER_REASON_ID && !rejectionValues?.note) {
            enqueueSnackbar(i18n.proposalForm.provideOtherRejectReason, { variant: 'error' });
            return false;
        }

        try {
            const response = await rejectProposalForm(safeVenueId, safeProposalId, {
                rejectionReason: rejectionReasons.find(reason => reason.id === rejectionValues.reason)?.reason ?? '',
                rejectionNotes: rejectionValues.note ?? '',
            });
            setProposalForm(response);

            return true;
        } catch {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
            return false;
        }
    };

    const onRejectWithOutReasons = async () => {
        try {
            const response = await rejectProposalForm(safeVenueId, safeProposalId);
            setProposalForm(response);

            return true;
        } catch {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
            return false;
        }
    };

    const onReject = enableTurnDown ? onRejectWithReasons : onRejectWithOutReasons;

    const onCancel = async () => {
        try {
            const response = await cancelProposalForm(safeVenueId, safeProposalId);
            setProposalForm(response);

            return true;
        } catch {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
            return false;
        }
    };

    const onRejectExtension = async () => {
        try {
            const response = await rejectProposalExtension(safeProposalId);
            setProposalForm(response);

            return true;
        } catch {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
            return false;
        }
    };

    const onUpdateVenueSpaces = async (updatedSpace: TSpacePOST = {}) => {
        const { id, ...spaceData } = updatedSpace;

        try {
            const props = {
                venueId: safeVenueId,
                proposalId: safeProposalId,
                data: spaceData,
            };

            const response = id ? await putVenueSpace({ ...props, spaceId: id }) : await postVenueSpace(props);

            const spaces = response.options.venueSpaces;
            setProposalForm(({ options, ...rest }) => ({
                ...rest,
                options: options ? { ...options, venueSpaces: spaces } : options,
            }));
            return spaces;
        } catch (e) {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
            return false;
        }
    };

    if (authError) {
        const code = authError.code;
        return <GenericError message={code === 401 ? i18n.error.contactSupport : i18n.error.contactOrRefreshPage} />;
    }

    if (loadError) {
        const code = loadError.code;
        return <GenericError message={code === 401 ? i18n.error.contactOrRevisit : i18n.error.contactSupport} />;
    }

    return loading ? (
        <FullScreenCentered>
            <Spinner />
        </FullScreenCentered>
    ) : (
        <FormManager
            proposalForm={proposalForm}
            onSave={onSave}
            onSubmit={onSubmit}
            onReject={onReject}
            onCancel={onCancel}
            onRejectExtension={onRejectExtension}
            onUpdateVenueSpaces={onUpdateVenueSpaces}
            inquiryRejectionReasons={rejectionReasons}
            handleRejectionValueChange={handleRejectionValueChange}
            rejectionValues={rejectionValues}
        />
    );
};
