import { TProposalForm } from 'components/ProposalForm/types';
import { get, post } from '.';



export const getAllProposals = () => get(`proposals/`, { showErrorCode: true });

export const bizlyOSVenueSearch = (
  body = {}
): Promise<{ venues: Bizly.Venue[]; meta: Bizly.SearchMeta }> =>
  post(`venues/search`, {
    ...body,
  }).then(res => ({ venues: res.venues, meta: res.searchMeta }));

export const getProposalForVenue = (venueId: number | string, proposalId: number | string) =>
  get(`venues/${venueId}/proposals/${proposalId}`, { showErrorCode: true }) as Promise<TProposalForm>;