import Tooltip from '@mui/material/Tooltip';
import ConfirmationModal from 'components/ConfirmationModal';
import { EventHeaderIconsFill } from 'components/EventHeader';
import Form from 'components/Form';
import { SpinnerOverlay } from 'components/Spinner';
import SubscriptionPrompt from 'components/SubscriptionPrompt';
import LabeledCheckbox from 'components/Ui-V2/Checkbox';
import Button from 'components/ui/Button';
import useShowModal from 'hooks/useShowModal';
import useUnsavedPrompt from 'hooks/useUnsavedPrompt';
import isEmpty from 'lodash/isEmpty';
import { useSnackbar } from 'notistack';
import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { NEW_INQUIRY_ID, currentInquiryActions, hasAcceptedProposal, useCurrentInquiry } from 'stores/current-inquiry';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, Copy, Row, SpacedRow, Spacer } from 'ui';
import { PageNotFound } from './PageNotFound';
import Planner, { ACCORDION_WIDTH } from './Planner';

const BuildInquiryPage = styled(Column)`
    height: 100%;
    max-width: 1080px;
`;

export const PageDescription = styled(Copy)`
    font-weight: 400;
    font-size: 15px;
    line-height: 19.5px;
    white-space: pre-line;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};
`;

const DateHeader = styled(SpacedRow)`
    max-width: ${ACCORDION_WIDTH}px;
`;

const AutoRow = styled(Row)`
    width: auto;
`;

const DatesFlexibleCheckbox = styled(LabeledCheckbox)`
    max-height: 19px;
`;

const NotesForm = styled(Form)`
    width: ${ACCORDION_WIDTH}px;
`;

const notesForm = {
    fields: {
        notes: {
            type: 'textarea',
            prompt: i18n.venue.inquiry.notesForVenue,
            optional: true,
            options: {
                rows: 5,
                rowsMax: 5,
                placeholder: i18n.venue.inquiry.notesPlaceholder2,
            },
        },
    },
    schema: [{ key: 'notes', fields: ['notes'], spacing: false }],
};

export type TNotesValue = { notes?: string };

export const NotesField = ({
    value = {},
    onChange,
    readonly,
    disabled,
}: {
    value?: TNotesValue;
    onChange: (update: { value: TNotesValue }) => void;
    readonly?: boolean;
    disabled?: boolean;
}) => <NotesForm {...notesForm} value={value} onChange={onChange} disabled={disabled} readonly={readonly} />;

export function BuildInquiryContent({ editable, eventId }: { editable?: boolean; eventId: number }) {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useUser();

    //Inquiry
    const currentInquiry = useCurrentInquiry();
    const currentInquiryId = currentInquiry?.inquiry?.id;

    const [stagedInquiry, setStagedInquiry] = React.useState<Partial<BizlyAPI.Inquiry>>({});
    const hasSaved = isEmpty(stagedInquiry);

    const inquiryFormValue = React.useMemo(
        () => ({ ...currentInquiry?.inquiry, ...stagedInquiry }),
        [currentInquiry, stagedInquiry]
    );

    const submitted = !!inquiryFormValue.submittedAt;
    const disabled = !editable || submitted;

    const [redirectUrl, setRedirectUrl] = React.useState('');

    // Planner
    const [plannerData, setPlannerData] = React.useState<BizlyAPI.Planner>({});
    const [plannerHasSaved, setPlannerHasSaved] = React.useState(true);
    const [queuedPlannerSave, setQueuedPlannerSave] = React.useState(false);
    const NONE = '',
        SAVE = 'save',
        SUBMIT = 'submit';
    type TCallback = typeof NONE | typeof SAVE | typeof SUBMIT;
    const [plannerSavedCallback, setPlannerSavedCallback] = React.useState<TCallback>(NONE);

    const [saving, setSaving] = React.useState(false);

    const [showEditConfirm, setShowEditConfirm] = React.useState<boolean>(false);

    const [showSubmitConfirm, setShowSubmitConfirm] = React.useState<boolean>(false);

    const {
        modalShown: subscriptionModalShown,
        showModal: showSubscriptionModal,
        hideModal: hideSubscriptionModal,
    } = useShowModal();

    const onEdit = React.useCallback(async () => {
        if (!currentInquiry.inquiry) return;

        setShowEditConfirm(false);
        setSaving(true);
        try {
            const newInquiry = await currentInquiryActions.cancelAndCopy(currentInquiry.inquiry.id);
            if (newInquiry) navigate(`/event/${eventId}/venue/inquiries/${newInquiry.id}`, { replace: true });
        } catch {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
            setSaving(false);
        }
    }, [eventId, enqueueSnackbar, currentInquiry, navigate]);

    const onSave = React.useCallback(async () => {
        if (!plannerHasSaved) {
            setPlannerSavedCallback(SAVE);
            setQueuedPlannerSave(true);
        } else {
            setSaving(true);
            try {
                if (currentInquiryId) {
                    await currentInquiryActions.updateDraft(currentInquiryId, inquiryFormValue);
                } else {
                    await currentInquiryActions.createAndUpdateDraft(eventId, inquiryFormValue);
                }
                setStagedInquiry({});
            } catch {
                enqueueSnackbar(i18n.error.default, { variant: 'error' });
            } finally {
                setSaving(false);
            }
        }
    }, [plannerHasSaved, currentInquiryId, inquiryFormValue, eventId, enqueueSnackbar]);

    const onSubmit = React.useCallback(async () => {
        if (!plannerHasSaved) {
            setPlannerSavedCallback(SUBMIT);
            setQueuedPlannerSave(true);
        } else {
            const venues = currentInquiry?.inquiry?.venues || [];
            if (venues.length === 0) return enqueueSnackbar(i18n.venue.inquiry.error.noVenues, { variant: 'error' });

            const { eventSpaces, accommodations } = plannerData;

            const hasZeroCount = accommodations?.some(({ count }) => count === 0);

            if (hasZeroCount && !showSubmitConfirm) {
                setShowSubmitConfirm(true);
                return;
            } else if (hasZeroCount && showSubmitConfirm) {
                setShowSubmitConfirm(false);
            }

            const scheduleItemIds = [...(eventSpaces ?? []), ...(accommodations ?? [])].map(item => item.id);

            setSaving(true);
            try {
                if (currentInquiry?.inquiry?.id) {
                    await currentInquiryActions.updateAndSubmit(currentInquiry.inquiry.id, {
                        ...inquiryFormValue,
                        scheduleItemIds,
                    });

                    setStagedInquiry({});
                    setRedirectUrl(`/event/${eventId}/venue/inquiries`);
                }
            } catch (e) {
                if (e === 'Meeting Limit Reached.') {
                    showSubscriptionModal();
                } else {
                    enqueueSnackbar(i18n.error.default, { variant: 'error' });
                }
                setSaving(false);
            }
        }
    }, [
        plannerHasSaved,
        plannerData,
        eventId,
        currentInquiry,
        inquiryFormValue,
        enqueueSnackbar,
        showSubscriptionModal,
        showSubmitConfirm,
    ]);

    useEffect(() => {
        if (plannerHasSaved && !queuedPlannerSave) {
            if (plannerSavedCallback !== NONE) {
                setPlannerSavedCallback(NONE);
                if (plannerSavedCallback === SAVE) onSave();
                if (plannerSavedCallback === SUBMIT) onSubmit();
            }
        }
    }, [plannerHasSaved, queuedPlannerSave, plannerSavedCallback]);

    const renderUnsavedPrompt = useUnsavedPrompt(!hasSaved);

    useEffect(() => {
        // Using useEffect because it should wait until hasSaved value is updated
        if (redirectUrl) {
            navigate(redirectUrl);
        }
    }, [redirectUrl, navigate]);

    const canSubmit = useMemo(() => {
        const hasInquiryData =
            (plannerData.accommodations && plannerData.accommodations.length > 0) ||
            (plannerData.eventSpaces && plannerData.eventSpaces.length > 0);
        const hasVenues = currentInquiry.venues && currentInquiry.venues.length > 0;
        return hasInquiryData && hasVenues;
    }, [plannerData, currentInquiry]);

    return (
        <BuildInquiryPage itemSpacing="large">
            {renderUnsavedPrompt()}
            <EventHeaderIconsFill>
                {editable && (
                    <AutoRow itemSpacing="smallish">
                        {submitted ? (
                            <Button width={74} onClick={() => setShowEditConfirm(true)}>
                                {i18n.button.edit}
                            </Button>
                        ) : (
                            <>
                                <Button secondary width={74} onClick={onSave} disabled={saving}>
                                    {i18n.button.save}
                                </Button>
                                <Tooltip title={canSubmit ? '' : i18n.venue.inquiry.error.inquiryValidation}>
                                    <span>
                                        <Button width={135} onClick={onSubmit} disabled={!canSubmit || saving}>
                                            {i18n.venue.inquiry.submitInquiries}
                                        </Button>
                                    </span>
                                </Tooltip>
                            </>
                        )}
                    </AutoRow>
                )}
            </EventHeaderIconsFill>

            <Column itemSpacing="default">
                <DateHeader>
                    <DatesFlexibleCheckbox
                        label={i18n.proposalForm.header.datesAreFlexible}
                        onChange={e =>
                            setStagedInquiry(prevStaged => ({ ...prevStaged, flexibleDates: e.target.checked }))
                        }
                        isChecked={!!inquiryFormValue.flexibleDates}
                        disabled={disabled}
                    />
                </DateHeader>

                <Planner
                    asInquiryModule
                    onLoad={setPlannerData}
                    onHasSaved={setPlannerHasSaved}
                    queuedSave={queuedPlannerSave}
                    onQueuedSave={newData => {
                        setPlannerData(newData);
                        setQueuedPlannerSave(false);
                    }}
                    onSaveFailed={() => {
                        setQueuedPlannerSave(false);
                        setPlannerSavedCallback(NONE);
                    }}
                    disabled={disabled}
                />

                <NotesField
                    value={inquiryFormValue}
                    onChange={({ value }: { value: TNotesValue }) =>
                        setStagedInquiry(prevStaged => ({
                            ...prevStaged,
                            ...value,
                        }))
                    }
                    disabled={disabled}
                />
            </Column>

            <ConfirmationModal
                onDismiss={() => setShowEditConfirm(false)}
                onProceed={onEdit}
                headline={i18n.venue.inquiry.warning}
                prompt={
                    <span>
                        {i18n.venue.inquiry.warningLine1}
                        <Spacer small />
                        {i18n.venue.inquiry.warningLine2}
                    </span>
                }
                isActive={showEditConfirm}
            />

            <ConfirmationModal
                onDismiss={() => setShowSubmitConfirm(false)}
                onProceed={onSubmit}
                headline={i18n.venue.inquiry.warning}
                prompt={
                    <span>
                        {i18n.venue.inquiry.error.roomsRequired}
                        <Spacer small />
                        {i18n.venue.inquiry.error.roomsContinue}
                    </span>
                }
                isActive={showSubmitConfirm}
            />

            {subscriptionModalShown && user.team && (
                <SubscriptionPrompt currentTier={user.team.tier} onSkip={hideSubscriptionModal} />
            )}

            {saving && <SpinnerOverlay fixed />}
        </BuildInquiryPage>
    );
}

export default function BuildInquiry() {
    const navigate = useNavigate();
    const location = useLocation();
    React.useEffect(() => {
        if (!location.state?.shouldGoBack) {
            navigate(
                {
                    pathname: location.pathname,
                    search: location.search,
                },
                {
                    replace: true,
                    state: { ...location.state, shouldGoBack: true },
                }
            );
        }
    }, [location, navigate]);

    const {
        event: { id: eventId, editable, cancelledAt },
    } = useEvent();

    const { inquiryId: pageInquiryId } = useParams<{ inquiryId: string }>();
    const currentInquiry = useCurrentInquiry();
    const currentInquiryId = currentInquiry?.inquiry?.id;

    const booked = currentInquiry.venues && hasAcceptedProposal(currentInquiry.venues);

    const loading = !currentInquiry.loaded || currentInquiry.eventId !== eventId || currentInquiry.venues === null;
    const mismatchedInquiryId = !loading && parseInt(pageInquiryId ?? '') !== currentInquiryId;
    const badInquiryId = mismatchedInquiryId && pageInquiryId !== NEW_INQUIRY_ID;
    const currentInquiryExists = mismatchedInquiryId && pageInquiryId === NEW_INQUIRY_ID && !!currentInquiryId;

    React.useEffect(() => {
        if (currentInquiryExists)
            navigate(`/event/${eventId}/venue/inquiries/${currentInquiryId}`, {
                replace: true,
                state: location.state,
            });
    }, [navigate, location, currentInquiryExists, currentInquiryId, eventId]);

    if (loading) return <SpinnerOverlay />;
    if (badInquiryId) return <PageNotFound />;

    return <BuildInquiryContent editable={editable && !booked && !cancelledAt} eventId={eventId} />;
}
