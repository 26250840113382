import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Typography, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { EColors, getColor } from 'theme';
import { Button } from '../Button/Button';

const HeaderContainer = styled(Box)(({ theme: { getColor, EColors } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
    borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    height: '60px',
}));

const BackIcon = styled(KeyboardBackspaceIcon)(({ theme: { getColor, EColors } }) => ({
    fontSize: '1rem',
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.1),
    borderRadius: '50%',
    padding: '2px',
    cursor: 'pointer',
}));

const Badge = styled(Box)(({ theme: { spacing, shape } }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(0.5),
    borderRadius: shape.borderRadius,
    height: 30,
    padding: '0 10px',
}));

const UnSaveBadge = styled(Badge)(({ theme: { getColor, EColors } }) => ({
    background: getColor(EColors.bizlyOSWarning, 0.2),
    color: getColor(EColors.pureBlack),
}));

const LastUpdatedBadge = styled(Badge)(({ theme: { getColor, EColors } }) => ({
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.2),
    color: getColor(EColors.pureBlack),
}));

type ListingHeaderProps = {
    state: 'create' | 'edit' | 'view';
    propertyTitle?: string;
    lastModified?: string;
    isChanges?: boolean;
    onSave?: () => void;
    onCancel?: () => void;
};

export default function ListingHeader({
    state = 'create',
    propertyTitle,
    isChanges = true,
    lastModified,
}: ListingHeaderProps) {
    const navigate = useNavigate();

    return (
        <HeaderContainer>
            <Box display="flex" alignItems="center" gap={2.5}>
                <BackIcon onClick={() => navigate(-1)} />
                <Typography variant="h6" fontWeight={700}>
                    {state === 'create' ? 'Create New Listing' : propertyTitle}
                </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1.25}>
                {state !== 'view' ? (
                    <>
                        {isChanges && (
                            <UnSaveBadge>
                                <InfoOutlinedIcon fontSize="small" />
                                <Typography variant="body2" fontWeight={500}>
                                    Unsaved Changes
                                </Typography>
                            </UnSaveBadge>
                        )}
                        <Button variant="outline" sx={{ height: 30 }}>
                            Cancel
                        </Button>
                        <Button disabled={!isChanges} sx={{ height: 30 }}>
                            Save
                        </Button>
                    </>
                ) : (
                    <LastUpdatedBadge>
                        <HistoryOutlinedIcon fontSize="small" sx={{ color: getColor(EColors.bizlyOSPrimary) }} />
                        <Typography variant="body2" fontWeight={500}>
                            Last Updated:
                        </Typography>
                        <Typography variant="body2">{lastModified}</Typography>
                    </LastUpdatedBadge>
                )}
            </Box>
        </HeaderContainer>
    );
}
