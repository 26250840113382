import { DataGridPro, GridCellParams } from '@mui/x-data-grid-pro'; 
import { Button } from 'components/Ui-V2/Button/Button';
import { Spinner } from 'components/Spinner';
import { PageHeadline } from 'components/ui/Headline';
import { useSnackbar } from 'notistack';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useUser } from 'providers/user';
import { useEvent } from 'providers/event';
import { copyParcel, createParcel, deleteParcel, loadAttendees, loadParcels } from 'api';
import styled from 'styled-components';
import { i18n } from 'translation';
import { SpacedRow, Column, Row, Spacer } from 'ui';
import useShowModal from 'hooks/useShowModal';
import ParcelCreateModal from 'components/Communication/ParcelCreateModal';
import { createPlaybookParcel, deletePlaybookParcel, getPlaybookParcels } from 'api/playbooks';
import { ParcelFormState, TEventParcelContext } from 'pages/EditParcel/EditParcel';
import { createPlaybookActions } from 'pages/PlaybookWizard/store';
import { copyRegistrationSlugToClipboard, HOTEL_CONF, INVITE, NOTE, REGISTRATION_PAGE, SURVEY } from 'pages/EditParcel/utils';
import { Link, useNavigate } from 'react-router-dom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { MenuItem, Menu, Box } from '@mui/material';
import { parcelType } from '../../util';
import DeleteConfirmationModal from 'components/DeleteConfirmationModal';
import EmptyCommunicationsImage from 'images/empty-pages-assets/empty-estimates.png';
import EmptyVisual from 'components/Ui-V2/EmptyVisual';
import { EColors, getColor } from 'theme';
import SearchIcon from '@mui/icons-material/Search';
import { PageDescription } from 'pages/BuildInquiry';
import HowToPopper from 'components/HowToPopper/HowToPopper';

const Main = styled.div`
    width: 100%;
`;

const SearchContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    border: 1px solid ${getColor(EColors.lighterGrey)};
    border-bottom: none;
    padding: 15px 0;
    position: relative;
    border-radius: 4px;
`;

const SearchInput = styled.input`
    padding: 4px 8px 5px 36px;
    border: none;
    outline: none;
    font: inherit;
    flex-grow: 1;
    height: 1.4375em;
`;

const SearchIconStyled = styled(SearchIcon)`
    position: absolute;
    margin-left: 8px;
    color: ${getColor(EColors.darkerGrey)};
`;

const StatusCell = styled.div<{ status: string; }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    border-radius: 4px;
    color: ${getColor(EColors.pureBlack)}
    text-align: center;
    margin-top: 10px;

    ${props => props.status === 'Sent' && `
        background-color: ${getColor(EColors.green, 0.6)}
    `}

    ${props => props.status === 'Scheduled' && `
        background-color: ${getColor(EColors.preferredRank30, 0.6)}
    `}

    ${props => props.status === 'Draft' && `
        background-color: ${getColor(EColors.grey, 0.6)}
    `}

    ${props => props.status === 'Launched' && `
        background-color: ${getColor(EColors.primaryAction, 0.6)}
    `}
`;

const GridContainer = styled(Box)`
    height: 570px;
    width: 100%;
    margin-right: 5px;
`;

const StyledDataGridPro = styled(DataGridPro)({
    borderRadius: '4px',
    width: '100%',
    marginTop: '-10px',
    borderBottom: 'none',

    '& .MuiDataGrid-container--top [role=row], & .MuiDataGrid-container--bottom [role=row]': {
        backgroundColor: getColor(EColors.lighterGrey),
    },

    '& .MuiDataGrid-cell': {
        paddingRight: '20px',
        border: 'none',
        outline: 'none',
        backgroundColor: 'transparent',
        '&:focus': {
            outline: 'none',
            boxShadow: 'none',
        },
    },

    '& .MuiDataGrid-row:hover': {
        backgroundColor: getColor(EColors.lighterGrey, 0.3),
        cursor: 'pointer',
        border: 'none',
    },

    '& .MuiDataGrid-columnHeader': {
        backgroundColor: '#f5f5f5',
        border: 'none',
    },
});

const InputBox = styled(Box)`
    position: relative;
    border-bottom: 1px solid ${getColor(EColors.darkGrey)};
    margin-right: 15px;
    margin-bottom: 5px;
`;

const VisualContainer = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: center;
    height: 60vh;
`;

type CommunicationProps = {
    playbookId?: number | string;
    readonly?: boolean;
    asWizardModule?: boolean;
    showCreateButton?: boolean;
};

export default function Communication({ playbookId, readonly = false, showCreateButton = true }: CommunicationProps) {
    const { user } = useUser();
    const { event } = useEvent();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [deleteIntent, setDeleteIntent] = useState<Bizly.Parcel | null>(null);
    const [parcels, setParcels] = useState<Bizly.Parcel[]>([]);
    
    const { showModal, hideModal, modalShown } = useShowModal();
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
    const [activeRowId, setActiveRowId] = useState<string | null>(null);

    const handleMenuOpen = (event: React.MouseEvent<SVGElement>, rowId: string) => {
        const target = event.currentTarget as unknown as HTMLElement;
        if (activeRowId === rowId) {
            setMenuAnchor(null);
            setActiveRowId(null);
        } else {
            setMenuAnchor(target);
            setActiveRowId(rowId);
        }
    };
    
    const handleMenuClose = () => {
        setMenuAnchor(null);
        setActiveRowId(null);
    };

    const removeParcel = playbookId ? deletePlaybookParcel : deleteParcel;
    const id = playbookId ?? event.id;

    const editLinkDestination = (parcel: Bizly.Parcel) => {
        if (playbookId) {
            return `/playbooks/${playbookId}/edit/communication/edit/${parcelType(parcel)}/${parcel.id}`;
        }

        return `/event/${event.id}/communication/edit/${parcelType(parcel)}/${parcel.id}`;
    };

    const load = useCallback(async () => {
        try {
            if (playbookId) {
                const parcelsResponse = await getPlaybookParcels(playbookId);
                setParcels(parcelsResponse.parcels as Bizly.Parcel[]);
            } else {
                const parcelsResponse = await (loadParcels as (id: number | string) => Promise<Bizly.Parcel[]>)(id);
                setParcels(parcelsResponse as Bizly.Parcel[]);
            }
        } catch (e) {
            enqueueSnackbar(
                playbookId ? i18n.communication.error.fetchPlaybook(id) : i18n.communication.error.fetchMeeting(id)
            );
        } finally {
            setLoading(false);
        }
    }, [enqueueSnackbar, id, playbookId]);

    useEffect(() => {
        load();
    }, [load]);

    const formatDate = (dateString: string | null | undefined): string => {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
        return dateString ? new Date(dateString).toLocaleDateString('en-US', options) : "";
    };

    const handleCopyClick = async (parcel: Bizly.Parcel) => {
        if (!parcel.id) return;
        await copyParcel(parcel.id);
        load();
    };

    const handleDeleteClick = (parcel: Bizly.Parcel) => {
        setDeleteIntent(parcel);
    };

    const handleDeleteConfirm = async () => {
        if (!deleteIntent) return;
        await removeParcel(id, deleteIntent);
        load();
        setDeleteIntent(null);
    };

    const handleDeleteDismiss = () => {
        setDeleteIntent(null);
    };

    const filteredRows = (parcels || []).filter(parcel => parcel?.subject?.toLowerCase().includes(searchTerm.toLowerCase())).map(parcel => {
        const currentParcelType = parcel ? parcelType(parcel as Bizly.Parcel) : null;
        const isPublished = !Array.isArray(parcel?.traits) && parcel?.traits?.public?.registrationPage?.published;
        const isSubmitted =
            ((currentParcelType === INVITE ||
                currentParcelType === NOTE ||
                currentParcelType === SURVEY ||
                currentParcelType === HOTEL_CONF) &&
                parcel.sent) ||
            (currentParcelType === REGISTRATION_PAGE && isPublished) ||
            false;

        const sendDate = isSubmitted
            ? currentParcelType === REGISTRATION_PAGE
                ? Array.isArray(parcel.traits) ? "" : formatDate(parcel.traits?.public?.registrationPage?.publishedAt)
                : formatDate(parcel.sentAt)
            : parcel.sendAt
                ? formatDate(parcel.sendAt)
                : "";
        const communicationTypeMap: Record<string, string> = {
            registration_page: i18n.communication.registration_page,
            invite: i18n.communication.invite,
            survey: i18n.communication.survey,
            note: i18n.communication.noteSurvey,
            rsvp: i18n.communication.privateInvite,
            hotel_conf: i18n.communication.hotel_conf
        };
        const communicationType = communicationTypeMap[parcel.type as keyof typeof communicationTypeMap] || parcel.type;
        const status = isSubmitted
        ? currentParcelType === REGISTRATION_PAGE
            ? i18n.communication.launched
            : i18n.communication.sent
        : parcel.sendAt ? i18n.communication.scheduled : i18n.communication.draft;

        return {
            ...parcel,
            id: parcel.id,
            title: parcel.subject,
            sendDate,
            communicationType,
            status
        };
    }).sort((a, b) => (b.id ?? -Infinity) - (a.id ?? -Infinity));

    const columns = [
        { field: 'title', headerName: 'Title', flex: 1 },
        { field: 'sendDate', headerName: 'Sent Date', flex: 0.5 },
        { field: 'communicationType', headerName: 'Type', flex: 1 },
        { field: 'status', headerName: 'Status', flex: 0.5, renderCell: (params: GridCellParams) => (
            <StatusCell status={params.value as string}>
                {params.value as string}
            </StatusCell>
        )},
        { field: 'actions', headerName: 'Actions', flex: 0.5, renderCell: (params: GridCellParams) => {
            const type = params.row ? parcelType(params.row as Bizly.Parcel) : null;
            const isParcelPublished = !Array.isArray(params.row.traits) && params.row.traits?.public?.registrationPage?.published;
            const rowId = params.row.id;
            
            return (
                <>
                    <MoreHorizIcon
                        style={{ cursor: 'pointer', marginTop: '10px' }}
                        onClick={(e: React.MouseEvent<SVGElement>) => handleMenuOpen(e, rowId)}
                    />

                    <Menu anchorEl={menuAnchor} open={menuAnchor !== null && activeRowId === rowId} onClose={handleMenuClose}>
                        <Link to={editLinkDestination(params.row)}>
                            <MenuItem onClick={handleMenuClose}>
                                {params.row?.sent || isParcelPublished ? i18n.button.view : i18n.button.edit}
                            </MenuItem>
                        </Link>
                        {type === REGISTRATION_PAGE && isParcelPublished && (
                            <MenuItem
                                onClick={async () => {
                                    handleMenuClose();
                                    await copyRegistrationSlugToClipboard({ parcels: [params.row], id });
                                    enqueueSnackbar(i18n.communication.copiedURL, { variant: 'success' });
                                }}
                            >
                                {i18n.button.copyUrl}
                            </MenuItem>
                        )}
                        {(type === INVITE || type === NOTE || type === SURVEY) && (
                            <MenuItem
                                onClick={() => {
                                    handleMenuClose();
                                    handleCopyClick(params.row);
                                }}
                            >
                                {i18n.button.copy}
                            </MenuItem>
                        )}
                        {!params.row?.sent && !isParcelPublished && (
                            <MenuItem
                                onClick={() => {
                                    handleMenuClose();
                                    handleDeleteClick(params.row);
                                }}
                            >
                                {i18n.button.delete}
                            </MenuItem>
                        )}
                    </Menu>
                </>
            );
        }},
    ];

    const virtualMeetingServiceProvider = useMemo(
        () =>
            user.team?.virtualMeetingServiceProviders?.find(
                serviceProvider => serviceProvider.id === event?.virtualMeeting?.serviceProvider?.id
            ),
        [user.team, event?.virtualMeeting]
    );

    const handleCreate = async (type: Bizly.ParcelType) => {
        let attendees: Bizly.EventAttendee[] = [],
            recipients: number[] = [];
        if (!playbookId) {
            const response = await loadAttendees(event.id);
            attendees = response?.attendees || [];
            recipients = attendees.map(({ id }: { id: number }) => id);
        }
        const parcel: ParcelFormState = initialParcelState(type, recipients, event, virtualMeetingServiceProvider);
        parcel.subject = !playbookId && type === 'registration_page' ? event.name : '';

        try {
            const newParcelRes = playbookId
                ? await createPlaybookParcel(playbookId, parcel)
                : await createParcel(event.id, parcel);
            enqueueSnackbar(`${i18n.communication.draftCreated}.`, { variant: 'success' });
            const newParcelId = newParcelRes.parcel.id;
            if (playbookId) {
                createPlaybookActions.triggerParcelsLoad();
            }
            const redirectUrl = playbookId
                ? `/playbooks/${playbookId}/edit/communication/edit/${type}/${newParcelId}`
                : `/event/${event.id}/communication/edit/${type}/${newParcelId}`;
            navigate(redirectUrl);
        } catch (e) {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
            const redirectUrl = playbookId ? `/playbooks/${playbookId}/edit` : `/event/${event.id}/communication`;
            navigate(redirectUrl);
        }
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            {parcels && parcels.length ? (
                <Main>
                    <DeleteConfirmationModal
                        onDismiss={handleDeleteDismiss}
                        onProceed={handleDeleteConfirm}
                        prompt={i18n.communication.deletePrompt(
                            playbookId ? i18n.communication.playbook : i18n.communication.meeting
                        )}
                        isActive={!!deleteIntent}
                    />

                    {!playbookId && !readonly ? (
                        <SpacedRow>
                            <Column>
                                <Row alignItems="center">
                                    <PageHeadline withDescription>{i18n.communication.invitesCommunication}</PageHeadline>
                                    <HowToPopper sectionId="communications" />
                                </Row>
                                <PageDescription large>{i18n.communication.description}</PageDescription>
                            </Column>

                            <Button fullWidth={false} onClick={showModal}>
                                {i18n.communication.create}
                            </Button>
                        </SpacedRow>
                    ) : (
                        showCreateButton && (
                            <Row justifyContent="flex-end">
                                <Button fullWidth={false} onClick={showModal}>
                                    {i18n.communication.create}
                                </Button>
                            </Row>
                        )
                    )}

                    <Spacer larger />

                    <SearchContainer>
                        <InputBox>
                            <SearchIconStyled />
                            <SearchInput 
                                type="text" 
                                placeholder="Search Title" 
                                value={searchTerm}
                                onChange={e => setSearchTerm(e.target.value)}
                            />
                        </InputBox>
                    </SearchContainer>
                    <GridContainer>
                        <StyledDataGridPro
                            rows={filteredRows}
                            columns={columns}
                            sortingOrder={['desc', 'asc']}
                            sortingModel={[{ field: 'id', sort: 'desc' }]}
                        />
                    </GridContainer>
                </Main>
            ) : (
                <VisualContainer>
                    <EmptyVisual
                        image={<img src={EmptyCommunicationsImage} alt="Empty Communications" />}
                        title={i18n.communication.noInvitesCreatedYet}
                        description={i18n.communication.invitesDescription}
                        actionButton={
                            <Button sx={{ width: '125px' }} onClick={showModal}>
                                {i18n.communication.create}
                            </Button>
                        }
                    />
                </VisualContainer>
            )}

            <ParcelCreateModal open={modalShown} onClose={hideModal} onCreate={handleCreate} />
        </>
    );
}

const generateParcelTrait = (type: Bizly.ParcelType) => {
    if (type === INVITE) {
        return { rsvp: [] };
    }

    if (type === REGISTRATION_PAGE) {
        return ['rsvp', 'public'];
    }

    if (type === HOTEL_CONF) {
        return ['hotel_conf'];
    }

    return [];
};

const initialParcelState = (
    type: Bizly.ParcelType,
    recipients: number[],
    event: TEventParcelContext,
    vmServiceProvider?: Bizly.VirtualMeetingServiceProvider
) => {
    return {
        name: '',
        recipients,
        subject: '',
        content: '',
        locationName: event.bookedVenue?.name,
        locationAddress: event.bookedVenue?.address,
        locationCityState: event.bookedVenue?.cityState,
        traits: generateParcelTrait(type),
        ...(vmServiceProvider
            ? {
                  vmSharingMethod: 'share' as 'share' | 'dont_share',
              }
            : {}),
    };
};
