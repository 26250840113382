import MuiLoadingButton, { LoadingButtonProps as MuiLoadingButtonProps } from '@mui/lab/LoadingButton';
import { Button as MuiButton, ButtonProps as MuiButtonProps, alpha, lighten } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { InterpolationFunction, StyledComponentPropsWithRef, ThemedStyledProps } from '@mui/styled-engine';
import { EColorsKeys } from 'hooks/useThemedColor';
import { EColors } from 'theme';
import { BizlyMuiTheme } from 'types/cssprop';

const buttonStyle: InterpolationFunction<
    ThemedStyledProps<StyledComponentPropsWithRef<HTMLButtonElement>, BizlyMuiTheme>
> = ({ theme: { getColor, EColors }, colorKey = EColors.primaryAction }) => ({
    '&.MuiButton-root': {
        textTransform: 'none',
    },

    '&.MuiButton-containedPrimary': {
        backgroundColor: getColor(colorKey),
        color: getColor(EColors.pureWhite),
        padding: '0.375rem 1rem',
        borderRadius: '0.25rem',
        fontSize: '0.875rem',
        fontWeight: '500',
        marginRight: '1.25rem',
        textTransform: 'capitalize',

        '&:hover': {
            backgroundColor: lighten(getColor(colorKey), 0.1),
        },
    },

    '&.MuiButton-outlinedPrimary': {
        borderColor: getColor(colorKey),
        color: getColor(colorKey),
        backgroundColor: getColor(EColors.aliceBlue),
        padding: '0.375rem 1rem',
        borderRadius: '0.25rem',
        fontSize: '0.875rem',
        fontWeight: '500',
        textTransform: 'capitalize',
        border: `1px solid ${getColor(colorKey)}`,

        '&:hover': {
            borderColor: getColor(colorKey),
        },
    },

    '&.MuiButton-outlinedSecondary': {
        borderColor: getColor(EColors.pureWhite),
        color: getColor(EColors.totalAttendee),

        '&:hover': {
            // Need hover colors
            borderColor: 'transparent',
        },
    },

    '&.MuiButton-textPrimary': {
        color: getColor(colorKey),
        padding: '0.375rem 1rem',
        marginRight: '1.25rem',
        textTransform: 'capitalize',
    },

    '&.Mui-disabled': {
        color: alpha(getColor(EColors.pureBlack), 0.26),
        boxShadow: 'none',
        backgroundColor: alpha(getColor(EColors.pureBlack), 0.12),
        pointerEvents: 'none',
        cursor: 'default',
        borderColor: getColor(EColors.grey),
        padding: '0.375rem 1rem',
        borderRadius: '0.25rem',
        fontSize: '0.875rem',
        fontWeight: '500',
    },
});

const StyledButton = muiStyled(MuiButton)(buttonStyle);
const StyledLoadingButton = muiStyled(MuiLoadingButton)(buttonStyle);

interface IconButtonProps extends ButtonProps {
    leftIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    rightIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

interface ButtonProps extends Omit<MuiButtonProps, 'onClick'> {
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    colorKey?: EColorsKeys;
}

interface LoadingButtonProps extends MuiLoadingButtonProps {
    colorKey?: EColorsKeys;
}

export const Button = ({
    children,
    variant = 'contained',
    colorKey = EColors.primaryAction,
    ...props
}: ButtonProps) => {
    return (
        <StyledButton variant={variant} colorKey={colorKey} {...props}>
            {children}
        </StyledButton>
    );
};

export const LoadingButton = ({
    children,
    variant = 'contained',
    colorKey = EColors.primaryAction,
    ...props
}: LoadingButtonProps) => {
    return (
        <StyledLoadingButton variant={variant} colorKey={colorKey} {...props}>
            {children}
        </StyledLoadingButton>
    );
};

export const ButtonWithIcon = ({
    leftIcon: LeftIcon,
    rightIcon: RightIcon,
    children,
    variant = 'contained',
    ...props
}: IconButtonProps) => {
    return (
        <StyledButton
            variant={variant}
            {...props}
            startIcon={LeftIcon && <LeftIcon />}
            endIcon={RightIcon && <RightIcon />}
        >
            {children}
        </StyledButton>
    );
};
