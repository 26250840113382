import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGridPro, GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { getAllProposals } from 'api/bizlyOS';
import { GridContainer } from 'components/BizlyOS/GridContainer';
import { ProposalInquiriesToolBar } from 'components/BizlyOS/ProposalInquiriesToolBar';
import { PageHeadline } from 'components/Ui-V2/Headline/Headline';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { i18n } from 'translation';
import { tzMoment, userTimeZone } from 'utils/moment';

declare global {
    type StatusType =
        | 'All'
        | 'New Inquiry'
        | 'Proposal Sent'
        | 'Won'
        | 'Lost'
        | 'Inquiry Rejected'
        | 'Inquiry Withdrawn'
        | 'Proposal Withdrawn'
        | 'Proposal Expired'
        | 'Inquiry Expired';
}

interface ProposalRow {
    id: string;
    inquiryId: string;
    dateReceived: string;
    lastContact: string;
    customer: string;
    company: string;
    event: string;
    eventStartDate: string;
    eventEndDate: string;
    guestsRoomNights: number;
    eventSpaces: number;
    status: StatusType;
    venueId: number;
    venueName: string;
}

interface Proposal {
    id: string;
    inquiry: {
        id: string;
        submittedAt: string;
    };
    updatedAt: string;
    client: {
        firstName: string;
        lastName: string;
    };
    company: {
        name: string;
    };
    event: {
        name: string;
        startsAt: string;
        endsAt: string;
    };
    totals: {
        guestRoomNights: number;
        eventSpaces: number;
    };
    venue: {
        id: number;
        name: string;
        type: string;
    };
    sentAt: string | null;
    status: {
        friendlyName: StatusType;
    };
}

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
    flexGrow: 1,
    '& .MuiDataGrid-root': {
        border: 'none',
    },
    '& .MuiToggleButtonGroup-root': {
        margin: theme.spacing(0.5, 0),
    },
    '& .MuiDataGrid-columnHeader': {
        borderRight: 'none',
        backgroundColor: theme.palette.grey[100],
    },
}));

const columns: GridColDef[] = [
    { field: 'inquiryId', headerName: 'Inquiry ID', width: 100 },
    {
        field: 'dateReceived',
        headerName: 'Date Received',
        width: 120,
        type: 'string',
        valueGetter: params => {
            return tzMoment(params, 'UTC').tz(userTimeZone).format('MMM D, YYYY');
        },
    },
    {
        field: 'lastContact',
        headerName: 'Last Contact',
        width: 120,
        type: 'string',
        valueGetter: params => tzMoment(params, 'UTC').tz(userTimeZone).format('MMM D, YYYY'),
    },
    { field: 'customer', headerName: 'Customer', width: 180 },
    { field: 'company', headerName: 'Company', flex: 0.7, minWidth: 150 },
    { field: 'venueName', headerName: 'Venue', flex: 1, minWidth: 200 },
    { field: 'event', headerName: 'Event', flex: 1, minWidth: 200 },
    {
        field: 'eventStartDate',
        headerName: 'Start',
        width: 180,
        type: 'string',
        valueGetter: params => {
            return tzMoment(params, userTimeZone).format('MMM D, YYYY h:mma');
        },
    },
    {
        field: 'eventEndDate',
        headerName: 'End',
        width: 180,
        type: 'string',
        valueGetter: params => {
            return tzMoment(params, userTimeZone).format('MMM D, YYYY h:mma');
        },
    },
    { field: 'guestsRoomNights', headerName: 'Room Nights', width: 120, type: 'string' },
    {
        field: 'eventSpaces',
        headerName: 'Event Spaces',
        width: 120,
        type: 'string',
        valueFormatter: value => value,
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 150,
        renderCell: params => {
            const getStatusColor = (status: string) => {
                switch (status) {
                    case 'New Inquiry':
                        return { bg: '#FFF9C4', text: '#000000' };
                    case 'Proposal Sent':
                        return { bg: '#E3F2FD', text: '#000000' };
                    case 'Confirmed':
                        return { bg: '#E8F5E9', text: '#000000' };
                    case 'Won':
                        return { bg: '#E8F5E9', text: '#000000' };
                    case 'Lost':
                        return { bg: '#FFEBEE', text: '#000000' };
                    case 'Payment Due':
                        return { bg: '#F3E5F5', text: '#000000' };
                    default:
                        return { bg: '#F5F5F5', text: '#000000' };
                }
            };

            const { bg, text } = getStatusColor(params.value);

            return (
                <Box
                    sx={{
                        backgroundColor: bg,
                        color: text,
                        borderRadius: '.75rem',
                        padding: '.125rem .5rem',
                        fontSize: '.75rem',
                        fontWeight: 'medium',
                        display: 'inline-block',
                        textAlign: 'center',
                        lineHeight: 1.5,
                    }}
                >
                    {params.value}
                </Box>
            );
        },
    },
];

export const ProposalInquiries = () => {
    const [rows, setRows] = useState<ProposalRow[]>([]);
    const [filter, setFilter] = useState<StatusType>('All');
    const [loading, setLoading] = useState<boolean>(true);

    const navigate = useNavigate();

    useEffect(() => {
        const getProposals = async () => {
            try {
                const result = await getAllProposals();

                const formattedRows: ProposalRow[] = result?.proposals.map((proposal: Proposal) => ({
                    id: proposal.id,
                    inquiryId: proposal.inquiry.id,
                    dateReceived: proposal.inquiry.submittedAt,
                    lastContact: proposal.updatedAt,
                    customer: `${proposal.client.firstName} ${proposal.client.lastName}`,
                    company: proposal.company.name,
                    event: proposal.event.name,
                    eventStartDate: proposal.event.startsAt,
                    eventEndDate: proposal.event.endsAt,
                    guestsRoomNights: proposal.totals.guestRoomNights,
                    eventSpaces: proposal.totals.eventSpaces,
                    status: proposal.status.friendlyName,
                    venueId: proposal.venue.id,
                    venueName: proposal.venue.name,
                }));

                setRows(formattedRows);
            } catch (error) {
                console.error('Error fetching proposals:', error);
            } finally {
                setLoading(false);
            }
        };

        getProposals();
    }, []);

    const handleFilterChange = useCallback((newFilter: StatusType) => {
        setFilter(newFilter);
    }, []);

    const handleRowClick = (params: GridRowParams<ProposalRow>) => {
        navigate(`/os/venues/${params.row.venueId}/proposals/${params.row.id}`);
    };

    const filteredRows = useMemo(() => {
        if (filter === 'All') {
            return rows;
        } else {
            return rows.filter(row => row.status === filter);
        }
    }, [rows, filter]);

    return (
        <GridContainer>
            <PageHeadline withDescription bold>
                {i18n.proposals.inquiries}
            </PageHeadline>
            <StyledDataGridPro
                rows={filteredRows}
                columns={columns}
                loading={loading}
                rowHeight={50}
                onRowClick={handleRowClick}
                initialState={{
                    pinnedColumns: { left: ['status'] },
                }}
                slots={{
                    toolbar: ProposalInquiriesToolBar,
                }}
                slotProps={{
                    toolbar: {
                        onFilterChange: handleFilterChange,
                    },
                }}
            />
        </GridContainer>
    );
};
