import { Tab, TabProps, Tabs, TabsProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTab = styled(Tab)<TabProps>(({ theme: { getColor, EColors } }) => ({
    width: 14,
    '&.MuiTab-root': {
        minWidth: 'unset',
        width: 'auto',
        minHeight: 'unset',
        height: '30px',
        padding: '0 10px',
        borderRadius: 4,
        textTransform: 'unset',
    },
    '&.MuiTab-textColorInherit': {
        color: getColor(EColors.pureBlack),
    },
    '&.Mui-selected': {
        backgroundColor: getColor(EColors.bizlyOSPrimary, 0.3),
        color: getColor(EColors.pureBlack),
    },
}));

const StyledTabs = styled(Tabs)<TabsProps>(() => ({
    '&.MuiTabs-root': {
        minHeight: 'unset',
    },
    '& .MuiTabs-indicator': {
        opacity: 0,
    },
}));

export { StyledTab as Tab, StyledTabs as Tabs };
