import { Box, Grid, Tab, Tabs } from '@mui/material';
import {
    getBookingsForEvent,
    setBookingContractUrl,
    setBookingEstimates,
    setBookingFinalSpend,
    setBookingInvoiceUrl,
} from 'api';
import { uploadFile } from 'cloudinary';
import { useBudgetRequired } from 'components/EventCreateModal/utils';
import BudgetForm from 'components/FinalSpendTracking/BudgetForm';
import SpendEstimateForm from 'components/FinalSpendTracking/SpendEstimateForm';
import TotalSpendForm, { TTotalSpendForm } from 'components/FinalSpendTracking/TotalSpendForm';
import { SpinnerOverlay } from 'components/Spinner';
import { PageHeadline } from 'components/ui/Headline';
import { useSnackbar } from 'notistack';
import { EventContext } from 'providers/event';
import { useUser } from 'providers/user';
import { parse } from 'query-string';
import { useContext, useEffect, useReducer, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { hasAcceptedProposal, hasBookedVenue, useCurrentInquiry } from 'stores/current-inquiry';
import styled from 'styled-components';
import { EColors, getColor } from 'theme';
import { i18n } from 'translation';
import { Button, Row } from 'ui';
import { takeFirstIfArray } from 'utils';

const SPEND_ESTIMATE = 'estimate';
const TOTAL_SPEND = 'final';

const Container = styled.div`
    width: 100%;
    flex: 1;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    height: 100%;
    box-sizing: border-box;
    position: relative;

    display: grid;
    max-width: 1600px;
`;

const StyledBox = styled(Box)`
    margin: -1.25rem -0.625rem;
    width: 100%;
`;

const StyledRow = styled(Row)`
    border-bottom: 1px solid rgb(230, 229, 227);
    width: 100%;
`;

const ButtonContainer = styled(Box)`
    width: 60%;
    text-align: center;
`;

const TABS_CONTAINER_STYLES = {
    marginBottom: 32,
    width: '100%',
    borderBottom: '1px solid rgb(230, 229, 227)',
};

const TAB_STYLES = {
    padding: 0,
    marginRight: 8,
    marginLeft: 8,
};

const Content = styled.div`
    max-height: 959px;
    min-height: 20px;
    flex: 1;
    overflow: hidden;
    padding: 20px;
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
    margin-bottom: 2em;
`;

const BudgetLabel = styled.p`
    margin: 45px -10px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack)};
    width: fit-content;
    font-size: 14px;
`;

const DashboardFixedBottomActions = styled(Box)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    margin-left: -66px;
    margin-right: -36px;
    padding-inline: 36px;
    padding-top: 0.625rem;
    padding-bottom: 1.25rem;
    background-color: ${getColor(EColors.drWhite)};
    border-top: 1px solid ${getColor(EColors.lightGrey)};
`;

const StyledSaveButton = styled(Button)`
    &.MuiButton-root {
        background-color: ${({ theme }) => theme.getColor(EColors.primaryAction)};
        border: 1px solid ${({ theme }) => theme.getColor(EColors.primaryAction)};
        padding-inline: 2.45em;
        color: ${({ theme }) => theme.getColor(EColors.pureWhite)};
        width: 87px;
        margin-right: 1rem;
        &:hover {
            background-color: ${({ theme }) => theme.getColor(EColors.primaryActionHover)};
            color: ${({ theme }) => theme.getColor(EColors.pureWhite)};
            border: 1px solid transparent;
        }
        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
`;

const StyledCancelButton = styled(Button)`
    &.MuiButton-root {
        padding-inline: 1.88em;
        width: 87px;
        margin-right: 1rem;
        margin-left: 1rem;
        border-color: ${({ theme }) => theme.getColor(EColors.cancelledOrRejectedStatus)};
        color: ${({ theme }) => theme.getColor(EColors.cancelledOrRejectedStatus)};

        &:focus,
        &:hover {
            background-color: ${({ theme }) => theme.getColor(EColors.cancelledOrRejectedStatus)};
            color: ${({ theme }) => theme.getColor(EColors.pureWhite)};
            border: 1px solid transparent;
        }

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
`;

function convertToNumber(totalSpendFormValues: TTotalSpendForm) {
    const { av, catering, guestrooms, misc, roomRental, total, ...rest } = totalSpendFormValues;
    return {
        av: Number(av),
        catering: Number(catering),
        guestrooms: Number(guestrooms),
        misc: Number(misc),
        roomRental: Number(roomRental),
        total: Number(total),
        ...rest,
    };
}

const initialState = {
    loading: true,
    booking: null,
    spendEstimateForm: {},
    totalSpendForm: {},
    budget: '',
    initialBudget: '',
    initialSpendEstimateForm: {},
    initialTotalSpendForm: {},
    activeTab: SPEND_ESTIMATE,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_LOADING':
            return { ...state, loading: action.payload };
        case 'SET_BOOKING':
            return { ...state, booking: action.payload };
        case 'SET_SPEND_ESTIMATE_FORM':
            return { ...state, spendEstimateForm: action.payload };
        case 'SET_TOTAL_SPEND_FORM':
            return { ...state, totalSpendForm: convertToNumber(action.payload) };
        case 'SET_BUDGET':
            return { ...state, budget: action.payload };
        case 'SET_INITIAL_BUDGET':
            return { ...state, initialBudget: action.payload };
        case 'SET_INITIAL_SPEND_ESTIMATE_FORM':
            return { ...state, initialSpendEstimateForm: action.payload };
        case 'SET_INITIAL_TOTAL_SPEND_FORM':
            return { ...state, initialTotalSpendForm: action.payload };
        case 'SET_ACTIVE_TAB':
            return { ...state, activeTab: action.payload };
        default:
            return state;
    }
};

const SpendTracker = () => {
    const { event, onEventChange } = useContext(EventContext);
    const currentInquiry = useCurrentInquiry();
    const { user } = useUser();
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const navigate = useNavigate();

    const [state, dispatch] = useReducer(reducer, initialState);
    const { loading, booking, spendEstimateForm, totalSpendForm, budget, initialBudget, activeTab } = state;

    const teamCompliance = user.team?.compliance || {};

    const _hasBookedVenue = !!event.bookedVenue || (!!currentInquiry.venues && hasBookedVenue(currentInquiry.venues));

    const budgetRequired = useBudgetRequired();

    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        const queryParams = takeFirstIfArray(parse(location.search));
        if (queryParams.tab) {
            dispatch({ type: 'SET_ACTIVE_TAB', payload: queryParams.tab });
        }
    }, [location.search]);

    const changeTab = (newTab: string) => {
        dispatch({ type: 'SET_ACTIVE_TAB', payload: newTab });
        navigate(`${location.pathname}?tab=${newTab}`);
    };

    useEffect(() => {
        const eventBudget = event.budget ? event.budget.toString() : event.budgetPerPerson?.toString();
        dispatch({ type: 'SET_LOADING', payload: true });

        getBookingsForEvent(event.id)
            .then(({ bookings = [] }) => {
                if (mounted.current) {
                    const firstBooking = bookings[0] || {};
                    dispatch({ type: 'SET_BOOKING', payload: firstBooking });

                    const newSpendEstimateForm = {
                        ...firstBooking.estimates,
                        existingDocs: {
                            contract1: firstBooking.contractUrl || '',
                            contract2: firstBooking.contract2Url || '',
                        },
                    };
                    dispatch({ type: 'SET_SPEND_ESTIMATE_FORM', payload: newSpendEstimateForm });
                    dispatch({ type: 'SET_INITIAL_SPEND_ESTIMATE_FORM', payload: newSpendEstimateForm });

                    if (firstBooking.finalSpend) {
                        const newTotalSpendForm = {
                            ...firstBooking.finalSpend,
                            existingDocs: { invoice1: firstBooking.invoiceUrl, invoice2: firstBooking.invoice2Url },
                        };
                        dispatch({ type: 'SET_TOTAL_SPEND_FORM', payload: newTotalSpendForm });
                        dispatch({ type: 'SET_INITIAL_TOTAL_SPEND_FORM', payload: newTotalSpendForm });
                    }

                    dispatch({ type: 'SET_INITIAL_BUDGET', payload: eventBudget });
                    dispatch({ type: 'SET_LOADING', payload: false });
                }
            })
            .catch(() => dispatch({ type: 'SET_LOADING', payload: false }));

        dispatch({ type: 'SET_BUDGET', payload: eventBudget });
    }, [event]);

    const getExistingContracts = () => ({
        contract1: booking?.contractUrl,
        contract2: booking?.contract2Url,
    });

    const getExistingInvoices = () => ({
        invoice1: booking?.invoiceUrl,
        invoice2: booking?.invoice2Url,
    });

    const hasChanges = () => {
        let hasChanges = false;
        hasChanges = hasChanges || budget !== state.initialBudget;
        if (activeTab === SPEND_ESTIMATE) {
            hasChanges =
                hasChanges || JSON.stringify(spendEstimateForm) !== JSON.stringify(state.initialSpendEstimateForm);
        } else {
            if (
                totalSpendForm.total <= 0 ||
                ((totalSpendForm?.stagedDocs?.invoiceUrls || []).length === 0 &&
                    !totalSpendForm.existingDocs.invoice1 &&
                    !totalSpendForm.existingDocs.invoice2)
            ) {
                return false;
            }
            hasChanges = hasChanges || JSON.stringify(totalSpendForm) !== JSON.stringify(state.initialTotalSpendForm);
        }

        return hasChanges;
    };

    const handleCancel = () => {
        dispatch({ type: 'SET_BUDGET', payload: initialBudget });
        dispatch({ type: 'SET_SPEND_ESTIMATE_FORM', payload: state.initialSpendEstimateForm });
        dispatch({ type: 'SET_TOTAL_SPEND_FORM', payload: state.initialTotalSpendForm });
    };

    const handleSubmit = async () => {
        if (budget !== initialBudget) {
            await handleSubmitBudget(budget || '');
        }

        if (JSON.stringify(spendEstimateForm) !== JSON.stringify(state.initialSpendEstimateForm)) {
            await handleSubmitContracts({ contractUrls: spendEstimateForm?.stagedDocs?.contractUrls || [] });
        }

        if (JSON.stringify(totalSpendForm) !== JSON.stringify(state.initialTotalSpendForm)) {
            await handleSubmitInvoices({ invoiceUrls: totalSpendForm?.stagedDocs?.invoiceUrls || [] });
        }
    };

    const handleSubmitBudget = async (budget: string) => {
        if (!budget) return;
        dispatch({ type: 'SET_LOADING', payload: true });

        try {
            onEventChange({ ...event, budget });
            dispatch({ type: 'SET_BUDGET', payload: budget });
            if (activeTab === SPEND_ESTIMATE) {
                enqueueSnackbar(i18n.meetingDashboard.headerSection.spendTracking.budgetSaved, {
                    variant: 'info',
                });
            }
        } catch (err) {
            dispatch({ type: 'SET_LOADING', payload: false });
            enqueueSnackbar(i18n.error.submission, {
                variant: 'error',
            });
        }
    };

    const handleSubmitContracts = async (stagedDocs: { contractUrls: File[] }) => {
        if (!booking || !spendEstimateForm) return;

        const { contractUrl: existingContract1, contract2Url: existingContract2 } = booking;
        const [contract1, contract2] = stagedDocs.contractUrls || [];

        if (contract1?.name || contract2?.name || existingContract1 || existingContract2) {
            if (!spendEstimateForm.total) {
                return enqueueSnackbar(i18n.venue.proposal.estimateTotalRequired, {
                    variant: 'error',
                });
            }
        }
        let stagedUpload1, stagedUpload2;
        try {
            dispatch({ type: 'SET_LOADING', payload: true });

            if (contract1 && contract1.name) {
                const { url: firstUrl } = await uploadFile(contract1);
                stagedUpload1 = firstUrl;
            }

            if (contract2 && contract2.name) {
                const { url: secondUrl } = await uploadFile(contract2);
                stagedUpload2 = secondUrl;
            }

            if (existingContract1 && !existingContract2 && stagedDocs?.contractUrls?.length === 1) {
                const secondContract = stagedDocs.contractUrls[0]?.name
                    ? await uploadFile(stagedDocs.contractUrls[0])
                    : null;
                if (secondContract) {
                    stagedUpload1 = existingContract1;
                    stagedUpload2 = secondContract.url;
                }
            }

            const firstContract = stagedUpload1 || existingContract1;
            const secondContract = stagedUpload2 || existingContract2;

            const contractUrls = {
                contractUrl: firstContract,
                contract2Url: secondContract,
            };

            const { booking: bookedContract } = await setBookingContractUrl(booking.id, contractUrls);
            dispatch({ type: 'SET_BOOKING', payload: bookedContract });

            const { booking: updatedBooking } = await setBookingEstimates(booking.id, spendEstimateForm);
            dispatch({ type: 'SET_BOOKING', payload: { ...booking, ...updatedBooking } });

            if (activeTab === SPEND_ESTIMATE) {
                enqueueSnackbar(i18n.meetingDashboard.headerSection.spendTracking.estimatesSaved, {
                    variant: 'info',
                });
            }
        } catch (err) {
            enqueueSnackbar(i18n.error.submission, {
                variant: 'error',
            });
        } finally {
            dispatch({ type: 'SET_LOADING', payload: false });
        }
    };

    const handleSubmitInvoices = async (stagedDocs: { invoiceUrls?: File[] }) => {
        if (!booking || !totalSpendForm) return;
        const { invoiceUrl: existingInvoice1, invoice2Url: existingInvoice2 } = booking;
        const [invoice1, invoice2] = stagedDocs.invoiceUrls || [];

        if (invoice1?.name || invoice2?.name || existingInvoice1 || existingInvoice2) {
            if (isNaN(totalSpendForm.total) || Number(totalSpendForm.total) <= 0) {
                return enqueueSnackbar(i18n.venue.proposal.finalSpendRequired, {
                    variant: 'error',
                });
            }

            const requiredFields: Array<keyof TTotalSpendForm> = ['av', 'catering', 'guestrooms', 'misc', 'roomRental'];
            const isValid = requiredFields.every(field => {
                const value = totalSpendForm?.[field];
                return !isNaN(value);
            });

            if (!isValid) {
                return enqueueSnackbar(i18n.venue.proposal.finalSpendRequired, {
                    variant: 'error',
                });
            }
        }

        let stagedUpload1, stagedUpload2;
        try {
            dispatch({ type: 'SET_LOADING', payload: true });

            if (invoice1 && invoice1.name) {
                const { url: firstUrl } = await uploadFile(invoice1);
                stagedUpload1 = firstUrl;
            }

            if (invoice2 && invoice2.name) {
                const { url: secondUrl } = await uploadFile(invoice2);
                stagedUpload2 = secondUrl;
            }

            if (existingInvoice1 && !existingInvoice2 && stagedDocs?.invoiceUrls?.length === 1) {
                const secondInvoice = stagedDocs.invoiceUrls[0]?.name
                    ? await uploadFile(stagedDocs.invoiceUrls[0])
                    : null;
                if (secondInvoice) {
                    stagedUpload1 = existingInvoice2;
                    stagedUpload2 = secondInvoice.url;
                }
            }

            const firstInvoice = stagedUpload1 || existingInvoice1;
            const secondInvoice = stagedUpload2 || existingInvoice2;

            const invoiceUrls = {
                invoiceUrl: firstInvoice,
                invoice2Url: secondInvoice,
            };

            const { booking: updatedBookingInvoice } = await setBookingInvoiceUrl(booking.id, invoiceUrls);
            dispatch({ type: 'SET_BOOKING', payload: { ...booking, ...updatedBookingInvoice } });

            const { booking: updatedBookingTotals } = await setBookingFinalSpend(booking.id, totalSpendForm);
            dispatch({ type: 'SET_BOOKING', payload: { ...booking, ...updatedBookingTotals } });

            if (activeTab === TOTAL_SPEND) {
                enqueueSnackbar(i18n.meetingDashboard.headerSection.spendTracking.invoicesSaved, {
                    variant: 'info',
                });
            }

            onEventChange({ ...event, finalSpendTracked: true, ...(budget ? { budget } : {}) });
        } catch (err) {
            enqueueSnackbar(i18n.error.submission, {
                variant: 'error',
            });
        } finally {
            dispatch({ type: 'SET_LOADING', payload: false });
        }
    };

    if (loading) {
        return <SpinnerOverlay />;
    }

    return (
        <StyledBox>
            <StyledRow alignItems="center">
                <PageHeadline withDescription>
                    {i18n.meetingDashboard.headerSection.spendTracking.spendTracking}
                </PageHeadline>
            </StyledRow>
            <Container>
                {!loading && (
                    <Tabs value={activeTab} style={TABS_CONTAINER_STYLES}>
                        <Tab
                            label={i18n.meetingDashboard.headerSection.spendTracking.spendEstimate}
                            value={SPEND_ESTIMATE}
                            onClick={() => changeTab(SPEND_ESTIMATE)}
                            style={TAB_STYLES}
                        />
                        <Tab
                            label={i18n.meetingDashboard.headerSection.spendTracking.finalSpend}
                            value={TOTAL_SPEND}
                            onClick={() => changeTab(TOTAL_SPEND)}
                            style={TAB_STYLES}
                        />
                    </Tabs>
                )}

                {activeTab === SPEND_ESTIMATE && !loading && (
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item md={4}>
                                <Content>
                                    <Grid container spacing={2}>
                                        <Grid item md={9}>
                                            <BudgetForm
                                                value={budget || ''}
                                                onChange={value => dispatch({ type: 'SET_BUDGET', payload: value })}
                                                hideHeader
                                                disabled={!event.editable || budgetRequired}
                                            />
                                        </Grid>
                                        <Grid item md={3} display="flex">
                                            <BudgetLabel>
                                                {event.budget
                                                    ? i18n.homepage.createMeetingModal.grandTotal
                                                    : i18n.homepage.createMeetingModal.perPerson}
                                            </BudgetLabel>
                                        </Grid>
                                    </Grid>
                                </Content>
                            </Grid>
                            <Grid item md={8}>
                                <Content style={{ minHeight: '500px' }}>
                                    <SpendEstimateForm
                                        canUpdate={
                                            !!event?.bookedVenue ||
                                            (currentInquiry.venues && hasAcceptedProposal(currentInquiry.venues)) ||
                                            false
                                        }
                                        onFormUpdate={form =>
                                            dispatch({ type: 'SET_SPEND_ESTIMATE_FORM', payload: form })
                                        }
                                        formValues={{
                                            ...spendEstimateForm,
                                            currency: event.currency?.code || 'USD',
                                            existingDocs: getExistingContracts(),
                                        }}
                                        teamCompliance={teamCompliance}
                                        disabled={!event.editable}
                                        hideHeader
                                    />
                                </Content>
                            </Grid>
                        </Grid>
                    </Box>
                )}
                {activeTab === TOTAL_SPEND && !loading && booking && (
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item md={4}>
                                <Content>
                                    <Grid container spacing={2}>
                                        <Grid item md={9}>
                                            <BudgetForm
                                                value={budget || ''}
                                                onChange={value => dispatch({ type: 'SET_BUDGET', payload: value })}
                                                hideHeader
                                                disabled={!event.editable || budgetRequired}
                                            />
                                        </Grid>
                                        <Grid item md={3} display="flex">
                                            <BudgetLabel>
                                                {event.budget
                                                    ? i18n.homepage.createMeetingModal.grandTotal
                                                    : i18n.homepage.createMeetingModal.perPerson}
                                            </BudgetLabel>
                                        </Grid>
                                    </Grid>
                                </Content>
                            </Grid>
                            <Grid item md={8}>
                                <Content style={{ minHeight: '500px' }}>
                                    <TotalSpendForm
                                        booking={booking}
                                        formValues={{
                                            ...totalSpendForm,
                                            currency: event.currency?.code || 'USD',
                                            existingDocs: getExistingInvoices(),
                                        }}
                                        onFormUpdate={form => dispatch({ type: 'SET_TOTAL_SPEND_FORM', payload: form })}
                                        teamCompliance={teamCompliance}
                                        hideHeader
                                        disabled={!event.editable}
                                        hasBookedVenue={_hasBookedVenue}
                                    />
                                </Content>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </Container>
            <DashboardFixedBottomActions>
                <ButtonContainer>
                    <StyledCancelButton variant="outlined" onClick={handleCancel} disabled={!hasChanges()}>
                        Cancel
                    </StyledCancelButton>

                    {event.editable && (
                        <StyledSaveButton onClick={() => handleSubmit()} disabled={!hasChanges()}>
                            {i18n.button.save}
                        </StyledSaveButton>
                    )}
                </ButtonContainer>
            </DashboardFixedBottomActions>
        </StyledBox>
    );
};

export default SpendTracker;
