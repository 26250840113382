import AssessmentIcon from '@mui/icons-material/Assessment';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import HelpIcon from '@mui/icons-material/Help';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import LayersIcon from '@mui/icons-material/Layers';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { darken, lighten } from '@mui/material/styles';
import Footer from 'components/Footer';
import TeamSelector from 'components/TeamSelector';
import UserMenu from 'components/UserMenu';
import ThemedLogo from 'components/ui/ThemedLogo';
import usePrevious from 'hooks/usePrevious';
import BizlyCredit from 'images/icons/nav/bizly-credit.png';
import { useUser } from 'providers/user';
import React, { CSSProperties, ElementType, useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, ExternalLink, SpacedColumn, Spacer } from 'ui';
// import TextButton from './ui/Button/TextButton';

export const SIDE_NAV_WIDTH = 200;
export const SIDE_SMALL_NAV_WIDTH = 82;

const LOGO_HEIGHT = 35;

const ContentsWrapper = styled.div<{
    fillWidth?: boolean;
    fillHeight?: boolean;
    fullWidth?: boolean;
    fullHeight?: boolean;
    minimized?: boolean;
}>`
    position: relative;
    margin-left: ${({ minimized }) => (minimized ? `${SIDE_SMALL_NAV_WIDTH}px` : `${SIDE_NAV_WIDTH}px`)};

    padding: 1.5rem 2rem;
    padding-bottom: 8rem; /* Space for homescreenbanner */

    box-sizing: border-box;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    ${({ fillWidth, minimized }) =>
        fillWidth
            ? `
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: calc(100% - ${minimized ? `${SIDE_SMALL_NAV_WIDTH}px` : `${SIDE_NAV_WIDTH}px`});
    min-height: calc(100vh);
    `
            : ''}

    ${({ fillHeight }) =>
        fillHeight
            ? `
    padding-bottom: 0px;
    padding-top: 0;
    `
            : ''}

    ${({ fullWidth }) =>
        fullWidth
            ? `
    padding-left: 0;
    padding-right: 0;`
            : ''}

    ${({ fullHeight }) =>
        fullHeight
            ? `
    padding-top: 0;
    padding-bottom: 0;
    `
            : ''}
`;

const SideBarTop = styled.div`
    width: 100%;
    z-index: 100;
    height: ${LOGO_HEIGHT}px;
`;

const Side = styled(SpacedColumn)<{ minimized: boolean }>`
    position: fixed;
    width: ${({ minimized }) => (minimized ? `${SIDE_SMALL_NAV_WIDTH}px` : `${SIDE_NAV_WIDTH}px`)};
    height: 100%;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.sidenavForeground)};
    box-sizing: border-box;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand)};
    z-index: 200;

    ${SideBarTop} {
        ${({ minimized }) =>
            minimized
                ? `
        text-align: center;
        `
                : ``};
    }

    .menu-container {
        padding: 20px ${({ minimized }) => (minimized ? 15 : 20)}px;
    }

    .credit-container {
        padding: 0 10px;
    }
`;

const SideBarContainer = styled(Column)`
    flex: 1;
`;

const SideBarBottom = styled.div`
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 20px 16px;
    box-sizing: border-box;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand)};
    position: sticky;
`;

const SidebarLink = styled.span<{ active?: boolean; icon?: boolean }>`
    color: ${({ theme: { getColor, EColors } }) => lighten(getColor(EColors.sidenavForeground), 0.4)};
    cursor: pointer;
    padding: 10px 15px;
    display: flex;
    align-items: center;

    ${props =>
        props.icon &&
        `
        justify-content: center;
    `}

    ${({ active, theme: { getColor, EColors, isThemeBright } }) =>
        active &&
        `
        background: ${isThemeBright ? darken(getColor(EColors.brand), 0.5) : lighten(getColor(EColors.brand), 1)};
        color: ${isThemeBright ? lighten(getColor(EColors.sidenavForeground), 1) : darken(getColor(EColors.sidenavForeground), 1)};
        font-weight:600;
        border-radius:6px;
    `}

    svg {
        path {
            stroke: ${({ active, theme: { getColor, EColors } }) =>
                active
                    ? darken(getColor(EColors.sidenavForeground), 1)
                    : lighten(getColor(EColors.sidenavForeground), 0.4)};
            fill: ${({ active, theme: { getColor, EColors } }) =>
                active
                    ? darken(getColor(EColors.sidenavForeground), 1)
                    : lighten(getColor(EColors.sidenavForeground), 0.4)};
        }
    }
`;

const IconContainer = styled.div`
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Credit = styled(Box)`
    padding: 10px 20px;
    background-color: ${({ theme: { getColor, EColors, isThemeBright } }) =>
        isThemeBright ? lighten(getColor(EColors.brand), 0.5) : darken(getColor(EColors.brand), 0.5)};
    box-shadow:
        0px 1px 0px rgba(26, 26, 26, 0.07),
        inset 1px 0px 0px rgba(0, 0, 0, 0.13),
        inset -1px 0px 0px rgba(0, 0, 0, 0.13),
        inset 0px -1px 0px rgba(0, 0, 0, 0.17),
        inset 0px 1px 0px rgba(204, 204, 204, 0.5);
    border-radius: 6px;
`;

const CreditScore = styled.h3`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack)};
    font-size: 1em;
    margin: 3px 0;
`;

const CreditDescription = styled.div`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.black)};
    font-size: 0.8em;
`;

const StyledTooltip = styled(Tooltip).attrs({
    slotProps: {
        popper: {
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, -21],
                    },
                },
            ],
        },
    },
})``;

const NavTitle = styled.span`
    margin-left: 15px;
`;

type NavItem = { to: string; external?: boolean; title: string; Icon: ElementType };

const HOME_PATH = '/',
    EVENTS_PATH = '/events',
    SEARCH_PATH = '/search',
    PLAYBOOKS_PATH = '/playbooks',
    VENUES_PATH = '/venues',
    REPORTING_PATH = '/reporting',
    HELP_PATH = 'https://help.bizly.com';

const PATHS = [
    {
        matches: [/^\/$/],
        path: HOME_PATH,
    },
    {
        matches: [/^\/events/],
        path: EVENTS_PATH,
    },
    {
        matches: [/^\/search/, /^\/event/],
        path: SEARCH_PATH,
    },
    {
        matches: [/^\/playbooks/],
        path: PLAYBOOKS_PATH,
    },
    {
        matches: [/^\/venues/],
        path: VENUES_PATH,
    },
    {
        matches: [/^\/reporting/],
        path: REPORTING_PATH,
    },
];

export default function SideNav({
    fillWidth,
    fillHeight,
    fullWidth,
    fullHeight,
    hideUser,
    minimized,
    routeChildren,
    children,
    className,
    style,
}: Readonly<{
    fillWidth?: boolean;
    fillHeight?: boolean;
    fullWidth?: boolean;
    fullHeight?: boolean;
    hideUser?: boolean;
    minimized?: boolean;
    routeChildren?: React.ReactNode;
    children?: React.ReactNode;
    className?: string;
    style?: CSSProperties;
}>) {
    const { user, isTeamAdmin, toggles } = useUser();
    const location = useLocation();

    const highlightedPath = useMemo(() => {
        const match = PATHS.find(path => path.matches.some(pattern => RegExp(pattern).exec(location.pathname)));
        return match?.path;
    }, [location.pathname]);

    const prev = usePrevious(routeChildren);

    const showChildren = !!routeChildren;

    const renderNavItem = useCallback(
        ({ to, title, Icon }: NavItem) => (
            <StyledTooltip title={minimized ? title : ''} placement="top">
                <SidebarLink icon={minimized} active={highlightedPath === to}>
                    <IconContainer>
                        <Icon fontSize="small" />
                    </IconContainer>
                    {!minimized && <NavTitle>{title}</NavTitle>}
                </SidebarLink>
            </StyledTooltip>
        ),
        [minimized, highlightedPath]
    );
    const renderNavLink = useCallback(
        (props: NavItem) => {
            const { to, external } = props;
            return external ? (
                <ExternalLink href={to} openInNewTab>
                    {renderNavItem(props)}
                </ExternalLink>
            ) : (
                <Link to={to}>{renderNavItem(props)}</Link>
            );
        },
        [renderNavItem]
    );

    const navItems: NavItem[] = useMemo(
        () => [
            ...(user.team?.sourceSite && !showChildren
                ? [
                      {
                          to: user.team.sourceSite.url,
                          title: i18n.homepage.sideNav.sourceSiteTitle,
                          Icon: KeyboardReturnIcon,
                      },
                  ]
                : []),
            {
                to: HOME_PATH,
                title: i18n.homepage.sideNav.events,
                Icon: CalendarMonth,
            },
            ...(user.team?.sourceSite && !showChildren
                ? [
                      {
                          to: VENUES_PATH,
                          title: i18n.homepage.sideNav.venues,
                          Icon: TravelExploreIcon,
                      },
                      {
                          to: PLAYBOOKS_PATH,
                          title: i18n.homepage.sideNav.templates,
                          Icon: LayersIcon,
                      },
                  ]
                : [
                      {
                          to: PLAYBOOKS_PATH,
                          title: i18n.homepage.sideNav.templates,
                          Icon: LayersIcon,
                      },
                      {
                          to: VENUES_PATH,
                          title: i18n.homepage.sideNav.venues,
                          Icon: TravelExploreIcon,
                      },
                  ]),
            ...(isTeamAdmin
                ? [
                      {
                          to: REPORTING_PATH,
                          title: i18n.homepage.sideNav.reporting,
                          Icon: AssessmentIcon,
                      },
                  ]
                : []),
            {
                to: HELP_PATH,
                title: i18n.homepage.sideNav.help,
                Icon: HelpIcon,
                external: true,
            },
        ],
        [isTeamAdmin, showChildren, user.team]
    );

    return (
        <>
            <Side minimized={!!minimized}>
                <SideBarContainer itemSpacing="medium">
                    <div className="menu-container">
                        <SideBarTop>
                            <Link to={HOME_PATH}>
                                {!minimized ? <ThemedLogo /> : <ThemedLogo isMinimized={minimized} />}
                            </Link>
                        </SideBarTop>
                        <Spacer default />
                        <Column itemSpacing="small">
                            {showChildren ? routeChildren || prev : navItems.map(item => renderNavLink(item))}
                        </Column>
                    </div>
                    {false && !minimized && (
                        <div className="credit-container">
                            <Credit>
                                <Box display="flex" alignItems="flex-start" gap="10px">
                                    <img src={BizlyCredit} alt="bizly-credit" />
                                    <Box>
                                        <CreditScore>{i18n.homepage.sideNav.creditScore(25)}</CreditScore>
                                        <CreditDescription>{i18n.homepage.sideNav.creditDescription}</CreditDescription>
                                    </Box>
                                </Box>
                            </Credit>
                        </div>
                    )}
                </SideBarContainer>
                {!hideUser && (
                    <SideBarBottom>
                        <UserMenu minimized={minimized} />
                        <TeamSelector minimized={minimized} />
                    </SideBarBottom>
                )}
            </Side>
            {children && (
                <ContentsWrapper
                    fillWidth={fillWidth}
                    fillHeight={fillHeight}
                    fullWidth={fullWidth}
                    fullHeight={fullHeight}
                    className={className}
                    minimized={minimized}
                    style={style}
                >
                    {
                        children // TODO: Only necessary while supporting OldHome page
                    }
                </ContentsWrapper>
            )}
            {toggles.gate.useFooter && <Footer pageOffset={minimized ? SIDE_SMALL_NAV_WIDTH : SIDE_NAV_WIDTH} />}
        </>
    );
}
